import React from 'react'
import { REWARDS_CDN_URL } from '../../apollo/rewards-apollo-client'
import {format} from 'date-fns'
import moment from 'moment'
import styles from './Rewards.module.scss'
import cn from 'classnames'

const Rewards = (props) => {
  const businesses = props.businesses

  return (
    <>
      <div className={styles.banner_image}>


        {
          businesses?.map((business, index) => {
            if (index > 2) {
              return false
            }

            const rewards = business.rewards

            const businessImgUrl = business?.logoUrl ? `${REWARDS_CDN_URL}${business?.logoUrl}` : '/default_business_logo.svg'

            return (
              <div className={
                cn(
                  styles.reward_item_container,
                  index === 0 && styles.reward_first,
                  index === 1 && styles.reward_second,
                  index === 2 && styles.reward_third,
                )}
              >
                <div className={cn(styles.reward_topbar)}>
                  <img src="/mobile-top-bar.svg" alt="Top bar" className={cn(styles.reward_topbar_phone)}/>
                  <div className={styles.topbar_container}>
                    <img src="/black_arrow.svg" alt="Back button" />
                    <div className={styles.topbar_business}>
                      <mark className={styles.topbar_business_name}>{business?.name}</mark>
                      <span className={styles.topbar_business_member}>{business?.followers} members</span>
                    </div>
                    <div className={styles.topbar_logo}>
                      <img src={businessImgUrl} alt="Business Logo" className={styles.membership_header_logo}/>
                    </div>
                  </div>

                  <ul className={styles.topbar_menu}>
                    <li>Membership</li>
                    <li className={styles.topbar_menu_active}>Rewards</li>
                    <li>News</li>
                    <li>About</li>
                  </ul>
                </div>

                <div className={cn(styles.reward_list)}>
                  <p className={styles.store}>
                    Store cards ({rewards?.length < 3 || !rewards ? rewards?.length || 0 : 4 })
                  </p>

                  <div className={styles.rewards_item_wrap}>
                    {
                      rewards?.map((reward, index) => {
                        if (index > 3) {
                          return false
                        }
                        return (
                          <div className={styles.rewards_item}>
                            <img src={REWARDS_CDN_URL + reward.imageUrl} alt={reward.imageUrl} className={styles.card_image} />
                            <img src={REWARDS_CDN_URL + business.logoUrl} alt={business.logoUrl} className={styles.reward__business__logo}/>
                            <div className={styles.reward__card__name}>{reward.name}</div>
                            <div className={styles.reward__card__points}>{reward.pointCount} PTS TO GO!</div>
                            <div className={styles.reward__card__info}>
                              <div className={styles.reward__card__date}>Buy before {format(new Date(reward.activeTo), 'dd.mm')}</div>
                              <div className={styles.reward__card__exist}>{reward.availableCount} left</div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>

                </div>

                <div className={cn(styles.reward_navbar)}>
                  <div className={cn(styles.reward_navbar_item)}>
                    <img src="/mobile-menu-icon/home.svg" alt="Home" className="reward_menu_icon" />
                    <span>Home</span>
                  </div>
                  <div className={cn(styles.reward_navbar_item)}>
                    <img src="/mobile-menu-icon/business.svg" alt="Businesses" className="reward_menu_icon" />
                    <span>Businesses</span>
                  </div>
                  <div className={cn(styles.reward_navbar_item)}>
                    <img src="/mobile-menu-icon/scan-qr.svg" alt="Scan QR" className="reward_menu_icon" />
                    <span>Scan QR</span>
                  </div>
                  <div className={cn(styles.reward_navbar_item)}>
                    <img src="/mobile-menu-icon/activity.svg" alt="Activity" className="reward_menu_icon" />
                    <span>Activity</span>
                  </div>
                  <div className={cn(styles.reward_navbar_item)}>
                    <img src="/mobile-menu-icon/news.svg" alt="News" className="reward_menu_icon" />
                    <span>News</span>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    </>
  )
}

export default Rewards
